import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { NeedPermissionGuard } from './auth/need-permission.guard';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { DashboardComponent } from './home/dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';

const routes: Routes = [
  { path: 'sign-in', 
    title: 'Galaxy - Sign in', 
    component: SignInComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    title: 'Galaxy',
    canActivate: [AuthGuard],
    component: DashboardComponent,
  },
  {
    path: 'maintenance',
    title: 'Maintenance',
    component: MaintenanceComponent,
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'audios',
        title: 'Audios - Galaxy',
        data: {
          title: 'Audios',
          animation: 0,
          neededPermission: 'GALAXY_READER',
          type: 'audio',
        },
        canActivate: [NeedPermissionGuard],
        loadChildren: () =>
          import('./contents/contents.module').then((m) => m.ContentsModule),
      },
      {
        path: 'videos',
        title: 'Videos - Galaxy',
        data: {
          title: 'Videos',
          animation: 1,
          neededPermission: 'GALAXY_READER',
          type: 'video',
        },
        canActivate: [NeedPermissionGuard],
        loadChildren: () =>
        import('./contents/contents.module').then((m) => m.ContentsModule),
      },
      {
        path: 'apps',
        title: 'Apps - Galaxy',
        data: {
          title: 'Apps',
          animation: 2,
          neededPermission: 'GALAXY_READER',
          type: 'app',
        },
        canActivate: [NeedPermissionGuard],
        loadChildren: () =>
        import('./contents/contents.module').then((m) => m.ContentsModule),      },
      {
        path: 'images',
        title: 'Images - Galaxy',
        data: {
          title: 'Images',
          animation: 3,
          neededPermission: 'GALAXY_READER',
          type: 'image',
        },
        canActivate: [NeedPermissionGuard],
        loadChildren: () =>
        import('./contents/contents.module').then((m) => m.ContentsModule),
      },
      {
        path: 'contents',
        title: 'Contents - Galaxy',
        data: {
          title: 'Contents',
          animation: 3,
          neededPermission: 'GALAXY_READER',
          type: 'contents',
        },
        canActivate: [NeedPermissionGuard],
        loadChildren: () =>
          import('./contents/contents.module').then((m) => m.ContentsModule),
      },
      {
        path: 'carts',
        title: 'Carts - Galaxy',
        data: {
          title: 'Carts',
          animation: 4,
          neededPermission: 'GALAXY_ADMIN_SERIE',
        },
        canActivate: [NeedPermissionGuard],
        loadChildren: () =>
          import('./carts/carts.module').then((m) => m.CartsModule),
      },
      {
        path: 'campaigns',
        title: 'Campaigns - Fusio',
        data: {
          title: 'Campaigns',
          animation: 6,
          neededPermission: 'FUSIO_READER',
        },
        canActivate: [NeedPermissionGuard],
        loadChildren: () =>
          import('./campaign/campaign.module').then((m) => m.CampaignModule),
      },
      {
        path: 'search-engine-configs',
        title: 'Search Engine Configuration - Fusio',
        data: {
          title: 'Search Engine Configuration',
          animation: 7,
          neededPermission: 'TELESCOPE_ACCESS',
        },
        canActivate: [NeedPermissionGuard],
        loadChildren: () =>
          import('./search-engine/search-engine-routing.module').then((m) => m.SearchEngineRoutingModule),
      },
      {
        path: 'rules',
        title: 'Rules - Fusio',
        data: {
          title: 'Rules',
          animation: 8,
          neededPermission: 'FUSIO_RULE',
        },
        canActivate: [NeedPermissionGuard],
        loadChildren: () =>
          import('./rule/rule.module').then((m) => m.RuleModule),
      },
      {
        path: 'providers',
        title: 'Providers - Galaxy',
        data: {
          title: 'Providers',
          animation: 9,
          neededPermission: 'GALAXY_ADMIN_PROVIDER',
        },
        canActivate: [NeedPermissionGuard],
        loadChildren: () =>
          import('./provider/provider.module').then((m) => m.ProviderModule),
      },
      {
        path: 'series',
        title: 'Series - Galaxy',
        data: {
          title: 'Series',
          animation: 10,
          neededPermission: 'GALAXY_READER',
        },
        canActivate: [NeedPermissionGuard],
        loadChildren: () =>
          import('./serie/serie.module').then((m) => m.SerieModule),
      },
      {
        path: 'music',
        title: 'Music - Galaxy',
        data: {
          title: 'Music',
          animation: 11,
          neededPermission: 'GALAXY_READER',
          type: 'albums',
        },
        canActivate: [NeedPermissionGuard],
        loadChildren: () =>
          import('./albums/albums.module').then((m) => m.AlbumsModule),
      },
      {
        path: 'themes',
        title: 'Themes - Galaxy',
        data: {
          title: 'Themes',
          animation: 12,
          neededPermission: 'GALAXY_ADMIN_THEME',
        },
        canActivate: [NeedPermissionGuard],
        loadChildren: () =>
          import('./theme/theme.module').then((m) => m.ThemeModule),
      },
      {
        path: 'import-audio-products',
        title: 'Import audio products - Galaxy',
        data: {
          title: 'Import Audio Products',
          animation: 13,
          neededPermission: 'GALAXY_ADMIN_IMPORT_AUDIO',
        },
        canActivate: [NeedPermissionGuard],
        loadChildren: () =>
          import('./import-audio-product/import-audio-product.module').then(
            (m) => m.ImportAudioProductModule
          ),
      },
      {
        path: 'artists',
        title: 'Artists - Galaxy',
        data: {
          title: 'Artists',
          animation: 14,
          neededPermission: 'GALAXY_READER',
        },
        canActivate: [NeedPermissionGuard],
        loadChildren: () =>
          import('./artist/artist.module').then((m) => m.ArtistModule),
      },
      {
        path: 'documentation',
        title: 'Documentation - Galaxy',
        data: {
          title: 'Documentation',
          animation: 15,
          neededPermission: 'GALAXY_READER',
        },
        canActivate: [NeedPermissionGuard],
        loadChildren: () =>
          import('./documentation/documentation.module').then((m) => m.DocumentationModule),
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' , paramsInheritanceStrategy: 'always'})],
  exports: [RouterModule],
 
})
export class AppRoutingModule {}
